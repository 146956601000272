import { render, staticRenderFns } from "./referral-page.vue?vue&type=template&id=3df5b78f&"
import script from "./referral-page.vue?vue&type=script&lang=js&"
export * from "./referral-page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KButton from '@web/components/core/k-button'
import KLayout from '@web/components/core/k-layout'
import KLink from '@web/components/core/k-link'
import KProductTitle from '@web/components/core/k-product-title'
installComponents(component, {KButton,KLayout,KLink,KProductTitle})
